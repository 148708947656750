import Loader from "react-loaders";
// import me from "../../images/about/me.jpeg"
import me from "../../images/about/me.png"
import me2 from "../../images/about/me2.png"
import script from "../../images/about/script.svg"
import web from "../../images/about/web.svg"
import bot from "../../images/about/bot.svg"
function About() {
    return (
        <section className="about">
            <div className="container">
                <div className="horizontalLine"></div>
                <div className="about__wrapper">
                    <div className="photo"><img src={me2} alt='my_photo'></img></div>
                    <div className="description">
                        <h2 className="description__title">Обо мне</h2>
                        <div className="description__subtitle">
                            Меня зовут Ярослав
                        </div>
                        <div className="divider divider--about" />
                        <div className="description__text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                        </div>
                    </div>
                    <div className="skills">
                        <div className="skills__item">
                            <div className="skills__title">
                                <div className="skills__icon"><img src={web} alt="web" /></div>
                                <h3>Lorem ipsum (web)</h3>
                            </div>
                            <div className="divider divider--skills_item" />
                            <div className="skills__text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                            </div>
                        </div>
                        <div className="skills__item">
                            <div className="skills__title">
                                <div className="skills__icon"><img src={script} alt="web" /></div>
                                <h3>Lorem ipsum (script)</h3>
                            </div>
                            <div className="divider divider--skills_item" />
                            <div className="skills__text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                            </div>
                        </div>
                        <div className="skills__item">
                            <div className="skills__title">
                                <div className="skills__icon"><img src={bot} alt="web" /></div>
                                <h3>Lorem ipsum (bot)</h3>
                            </div>
                            <div className="divider divider--skills_item" />
                            <div className="skills__text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
  }
  
export default About;