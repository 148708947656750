import bg from "../../images/mainpage/bg.png"


function Home() {

    const backgroundStile = {
            backgroundImage: `url(${bg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
    };
    
    return (
        <>
            <section className="mainpage" style={backgroundStile}>
                <div className="container">
                    <div className="mainpage__wrapper">
                    
                        <h3 className="mainpage__start">Привет, меня Зовут Ярослав и Я</h3>
                        <h1 className="mainpage__middle">FULL-STACK <span className="mainpage__middle--purple">WEB</span> DEVELOPER</h1>
                        <h3 className="mainpage__end">Твои идеи + мои знания = отличный результат</h3>

                        <div className="mainpage__btns">
                            <a href="/" className="mainpage__link btn">Портфолио</a>
                            <a href="/" className="mainpage__link">Про меня</a>
                        </div>
                    </div>
                </div>
                {/* <img className="mainpage__photo" src={me} alt="me" /> */}
            </section>
        {/* <Loader type="line-scale" /> */}
        </>
    );
  }
  
export default Home;